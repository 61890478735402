<template>
  <div class="invoices text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        > 
              <v-toolbar-title>Pay Bill</v-toolbar-title>
        </v-toolbar>
          <v-row>
            <v-col>
            <v-text-field v-model="amount" placeholder="Please enter amount to continue..."></v-text-field>
            <div v-if="amount > 0" class="paymentDetails">
                <h2>Credit card details</h2>
                <v-text-field v-model="card.name" placeholder="Name on card"></v-text-field>
                <v-text-field v-model="card.number" type="number" placeholder="Credit card number"></v-text-field>
                <v-row>
                    <v-col>
                        <v-select
                        :items="months"
                        label="Expiration month"
                        v-model="card.expMonth"
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-select
                        :items="years"
                        label="Expiration year"
                        v-model="card.expYear"
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="card.cvc" type="number" placeholder="CVC"></v-text-field>
                    </v-col>
                </v-row>
                <h3>Billing Address</h3>
                <v-row>
                    <v-col>
                        <v-select
                        :items="states"
                        label="State"
                        v-model="card.address.region"
                        >
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="card.address.city" placeholder="City"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="card.address.streetAddress" placeholder="Street address"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field v-model="card.address.postalCode" placeholder="Postal code"></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div v-if="amount > 0">By pressing the Pay button I will be paying<br />
                <h2>{{amount}} USD</h2>
                to Prima Waste<br /><br />
                <v-btn color="primary" @click="pay" :loading="loading" :disabled="loading">Pay</v-btn>
            </div>
            </v-col>
          </v-row>
  </div>
</template>
<script>
export default {
    name: 'Pay Bill',
    data: () => ({
        uuid: '',
        loading: false,
        amount: '',
        months: [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '10',
            '11',
            '12',
        ],
        states: [
            "AL",
            "AK",
            "AS",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DE",
            "DC",
            "FL",
            "GA",
            "GU",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "ME",
            "MD",
            "MA",
            "MI",
            "MN",
            "MS",
            "MO",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "MP",
            "OH",
            "OK",
            "OR",
            "PA",
            "PR",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VA",
            "VI",
            "WA",
            "WV",
            "WI",
            "WY",
        ],
        years: [],
        card: {
            name: '',
            number: '',
            expMonth: '',
            address: {
                postalCode: '',
                country: 'US',
                region: '',
                streetAddress: '',
                city: '',
            },
            expYear: '',
            cvc: '',
        }
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        uuidv4: function(){
          return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
        },
        pay: function(){
            var este = this;
            este.loading = true;
            console.log('Sending payment');
            if(este.amount < 1){
                este.error('The amount should be greater than 1 USD.');
            } else if(este.card.name == ''){
                este.error('The name on card is required.');
            } else if(este.card.number < 100000000000000){
                este.error('The credit card number is incorrect.');
            } else if(este.card.expMonth == ''){
                este.error('The credit card expiration month is required.');
            } else if(este.card.expYear == ''){
                este.error('The credit card expiration year is required.');
            } else if(este.card.cvc == ''){
                este.error('The credit card CVC is required.');
            } else if(este.card.address.region == ''){
                este.error('The credit card billing address State is required.');
            } else if(este.card.address.city == ''){
                este.error('The credit card billing address City is required.');
            } else if(este.card.address.streetAddress == ''){
                este.error('The credit card billing address street is required.');
            } else if(este.card.address.postalCode == ''){
                este.error('The credit card billing address postal code is required.');
            }
            este.$http.post(este.$api + 'pay', {
              customer_id: este.$store.getters.customer_id,
              token: este.$store.getters.token,
              amount: este.amount,
              card: este.card,
              uuid: este.uuid,
            }).then(function(response){
              este.loading = false;
              if(response.status == 200 && response.data.status == 'ok'){
                    console.log('IMPRIME DATOS');
                    window.console.log(response.status);
                    este.clean();
                    este.ok(response.data.msg);
              } else {
                este.error(response.data.msg);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
                este.error('There was an error. Please try again.');
                window.console.log(err);
            });
        },
        clean: function(){
            var este = this;
            este.amount = '';
            este.card.name = '';
            este.card.number =  '';
            este.card.expMonth =  '';
            este.card.address.postalCode =  '';
            este.card.address.country =  'US';
            este.card.address.region =  '';
            este.card.address.streetAddress =  '';
            este.card.address.city =  '';
            este.card.expYear =  '';
            este.card.cvc =  '';
        },
        error: function(msg){
            var este = this;
            este.loading = false;
            var mensaje = {
                "color": "red",
                "timeout": 5000,
                "message": msg,
            }
            este.$store.commit("msgMuestra", mensaje);
            return true;
        },
        ok: function(msg){
            var este = this;
            este.loading = false;
            var mensaje = {
                "color": "green",
                "timeout": 5000,
                "message": msg,
            }
            este.$store.commit("msgMuestra", mensaje);
            return true;
        },
    },
    mounted: function(){
        this.$store.commit('title', 'Pay Bill');
        var inicio = this.$moment().format("YYYY");
        var fin = this.$moment().add('years', 10).format("YYYY");
        var este = this;
        var i;
        for(i=inicio; i<=fin; i++){
            este.years.push(i);
        }
        this.uuid = this.uuidv4();
    }
}
</script>
