<template>
  <div class="profile text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        > 
<v-tabs
    fixed-tabs
    background-color="red"
    dark
    v-model="tab"
  >
    <v-tab href="#profile">
      Profile
    </v-tab>
    <v-tab href="#changePassword">
      Change Password
    </v-tab>
  </v-tabs>
        </v-toolbar>
          <v-row>
            <v-col>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="profile">
                    <v-card flat>
                      <v-card-text>
                        <v-avatar size="150" color="grey lighten-2" @click="abreLogo">
                          <img v-if="logo"
                            :src="logo"
                            alt="Logo"
                          >
                          <v-icon light size="70" v-else>mdi-camera</v-icon>
                        </v-avatar>
                        <v-file-input
                            accept="image/png, image/jpeg"
                            prepend-icon="mdi-camera"
                            label="Company Logo"
                            v-model="newLogo"
                            ref="logoUpload"
                            @change="handleImage"
                         ></v-file-input>
                         <v-text-field 
                         prepend-icon="mdi-account"
                         label="User Name" v-model="username"></v-text-field>
                         <v-text-field 
                         prepend-icon="mdi-email"
                         label="E-mail" v-model="email"></v-text-field>
                         <v-btn color="primary" 
                          :loading="loading"
                          :disabled="loading"
                         @click="changeProfile">Update</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="changePassword">
                    <v-card flat>
                      <v-card-text>
                         <v-text-field label="Old Password" v-model="oldPassword" type="password"></v-text-field>
                         <v-text-field label="New Password" v-model="newPassword" type="password"></v-text-field>
                         <v-text-field label="Confirm Password" v-model="confirmPassword" type="password"></v-text-field>
                         <v-btn color="primary" 
                          :loading="loading2"
                          :disabled="loading2"
                         @click="changePassword">Change</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
            </v-col>
          </v-row>
  </div>
</template>
<script>
export default {
    name: 'My Profile',
    data: () => ({
        tab: '',
        logo: '',
        prevLogo: '',
        newLogo: '',
        username: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        loading: false,
        loading2: false,
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        abreLogo: function(){
            this.$refs.logoUpload.focus();
        },
        handleImage: function(){
            const selectedImage = this.newLogo;
            console.log(selectedImage);
            this.createBase64Image(selectedImage);
        },
        createBase64Image: function(fileObject){
            const reader = new FileReader();
            reader.onload = (e) => {
                this.logo = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        clean: function(){
            this.oldPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';
        },
        getProfile: function(){
          var este = this;
          este.$http.post(este.$api + 'profile', {
            customer_id: este.$store.getters.customer_id,
            token: este.$store.getters.token,
          }).then(function(response){
              console.log('ENTRO');
            if(response.status == 200 && response.data.status == 'ok'){
                  console.log('IMPRIME DATOS');
                  window.console.log(response.status);
                  este.logo = '';
                  este.prevLogo = '';
                  if (response.data.data.logo != null && response.data.data.logo.includes('customers')) {
                      este.logo = este.$api.replace('api/customer/','storage/') + response.data.data.logo;
                      este.prevLogo = este.$api.replace('api/customer/','storage/') + response.data.data.logo;
                  }
                  este.username = response.data.data.username;
                  este.email = response.data.data.email;
            } else {
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": response.data.msg
              }
              este.$store.commit("msgMuestra", mensaje);
              if('error' in response.data && response.data.error == 'logout'){
                  este.$router.push('/logout');
              }
            }
          }).catch(function(err){
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
          });
        },
        changeProfile: function(){
            console.log('Change Profile');
            var este = this;
            este.loading = true;
            if(!este.username || !este.email){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": 'User Name and Email are required.',
                }
                este.$store.commit("msgMuestra", mensaje);
                este.loading = false;
                return true;
            }
            var newSendLogo = '';
            if(este.logo && este.logo != este.prevLogo){
                newSendLogo = este.logo;
            }
            este.$http.post(este.$api + 'changeProfile', {
              customer_id: este.$store.getters.customer_id,
              token: este.$store.getters.token,
              username: este.username,
              email: este.email,
              logo: newSendLogo,
            }).then(function(response){
              var mensaje = {};
              este.loading = false;
              if(response.status == 200 && response.data.status == 'ok'){
                    console.log('IMPRIME DATOS');
                    window.console.log(response.status);
                    mensaje = {
                        "color": "green",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
              } else {
                mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
                este.loading = false;
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
            });
        },
        changePassword: function(){
            console.log('Change Password');
            var este = this;
            este.loading2 = true;
            if(!este.oldPassword || !este.newPassword || !este.confirmPassword || este.newPassword != este.confirmPassword){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": 'A field is missing or "New Password / Confirm Password" don\'t match. Please try again.',
                }
                este.$store.commit("msgMuestra", mensaje);
                este.loading2 = false;
                return true;
            }
            este.$http.post(este.$api + 'changePassword', {
              customer_id: este.$store.getters.customer_id,
              token: este.$store.getters.token,
              oldPassword: este.oldPassword,
              newPassword: este.newPassword,
            }).then(function(response){
              var mensaje = {};
              este.loading2 = false;
              if(response.status == 200 && response.data.status == 'ok'){
                    console.log('IMPRIME DATOS');
                    window.console.log(response.status);
                    este.clean();
                    mensaje = {
                        "color": "green",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
              } else {
                mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
                este.loading2 = false;
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
            });
        },
    },
    mounted: function(){
        this.$store.commit('title', 'My Profile');
        this.getProfile();
    }
}
</script>
