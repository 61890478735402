<template>
  <div class="invoices text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        > 
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
        </v-toolbar>
          <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="manifests"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                  >
                <template v-slot:item.pdf="{item}">
                    <v-btn icon color="red" @click="download(item);">
                        <v-icon>mdi-file-pdf-box</v-icon>
                    </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
  </div>
</template>
<script>
export default {
    name: 'Manifests',
    data: () => ({
        manifests: [],
        headers: [
            {text: 'Location', value:'serviceAddress'},
            {text: 'Manifest Number', value:'manifestNumber'},
            {text: 'Date', value:'pickUpDate'},
            {text: 'PDF', value:'pdf'},
        ],
        search: '',
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        download: function(pdf){
            window.open("https://primawm.com/storage/" + pdf.pdf, '_blank');
        },
        getManifests: function(){
          var este = this;
          este.$http.post(este.$api + 'manifests', {
            customer_id: este.$store.getters.customer_id,
            token: este.$store.getters.token,
          }).then(function(response){
            if(response.status == 200 && response.data.status == 'ok'){
                  console.log('IMPRIME DATOS');
                  window.console.log(response.status);
                  este.manifests = response.data.data;
            } else {
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": response.data.msg
              }
              este.manifests = [];
              este.$store.commit("msgMuestra", mensaje);
              if('error' in response.data && response.data.error == 'logout'){
                  este.$router.push('/logout');
              }
            }
          }).catch(function(err){
              este.manifests = [];
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
          });
        }
    },
    mounted: function(){
        this.$store.commit('title', 'Manifests');
        this.getManifests();
    }
}
</script>
