<template>
  <div class="specialrequest text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        > 
              <v-toolbar-title>Special Request</v-toolbar-title>
        </v-toolbar>
          <v-row>
            <v-col cols="12" md="5">
                <h3>Choose date</h3>
                <v-date-picker v-model="date" color="green lighten-1" header-color="primary" :allowed-dates="allowedDates" :min="currentDate" @update:picker-date="pickerUpdate($event)"></v-date-picker>
            </v-col>
            <v-col cols="12" md="7" class="pt-10">
                <v-select
                  :items="locations"
                  item-text="serviceAddress"
                  item-value="id"
                  label="Choose Location"
                  v-model="location"
                  outlined
                ></v-select>
                <v-textarea
                  class="mx-2"
                  label="Additional Notes"
                  v-model="notes"
                  rows="1"
                  auto-grow="true"
                  prepend-icon="mdi-comment"
                ></v-textarea>
                 <v-btn color="primary" 
                  :loading="loading"
                  :disabled="loading"
                 @click="submit">Submit</v-btn>
            </v-col>
          </v-row>
  </div>
</template>
<script>
export default {
    name: 'Special Request',
    data: () => ({
        date: '',
        currentDate: '',
        notes: '',
        availableDates: [],
        locations: [],
        location: '',
        loading: false,
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        clean: function(){
            this.date = this.$moment().format("YYYY-MM-DD");
            this.notes = '';
            this.location = '';
        },
        submit: function(){
            console.log('Submit');
            var este = this;
            este.loading = true;
            if(!este.location){
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": 'A location is required.',
                }
                este.$store.commit("msgMuestra", mensaje);
                este.loading = false;
                return true;
            }
            este.$http.post(este.$api + 'specialRequest', {
              customer_id: este.$store.getters.customer_id,
              token: este.$store.getters.token,
              date: este.date,
              location: este.location,
              notes: este.notes,
            }).then(function(response){
              var mensaje = {};
              este.loading = false;
              if(response.status == 200 && response.data.status == 'ok'){
                    console.log('IMPRIME DATOS');
                    window.console.log(response.status);
                    este.clean();
                    mensaje = {
                        "color": "green",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
              } else {
                mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
                if('error' in response.data && response.data.error == 'logout'){
                    este.$router.push('/logout');
                }
              }
            }).catch(function(err){
                este.loading = false;
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(err);
            });
        },
        allowedDates(a) {
            return this.availableDates.includes(a);
        },
        pickerUpdate: function(val) {
          let totalDay = this.$moment(val, "YYYY-MM").daysInMonth()
          console.log(totalDay)
          
          let availableDates = []
            
          let monthNow = this.$moment().format('M')
          let monthSelected = this.$moment(val).format('M')
          let day

          if(monthNow == monthSelected)
            day = this.$moment().format('D')
          else
            day = 1
          
          for (let i = day; i <= totalDay ; i++) {
              let date = this.$moment().month(val.split('-')[1]-1).date(i).format("YYYY-MM-DD")
              if (this.$moment(date).day() !== 0 && this.$moment(date).day() != 6)
                availableDates.push(date)
          }
          this.availableDates = availableDates;
          this.allowedDates();
        },
        getLocations: function(){
          var este = this;
          este.$http.post(este.$api + 'locations', {
            customer_id: este.$store.getters.customer_id,
            token: este.$store.getters.token,
          }).then(function(response){
            if(response.status == 200 && response.data.status == 'ok'){
                  console.log('IMPRIME DATOS');
                  window.console.log(response.status);
                  este.locations = response.data.data;
            } else {
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": response.data.msg
              }
              este.locations = [];
              este.$store.commit("msgMuestra", mensaje);
              if('error' in response.data && response.data.error == 'logout'){
                  este.$router.push('/logout');
              }
            }
          }).catch(function(err){
              este.locations = [];
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
          });
        }
    },
    mounted: function(){
        this.$store.commit('title', 'Special Request');
        this.date = this.$moment().format("YYYY-MM-DD");
        this.currentDate = this.$moment().format("YYYY-MM-DD");
        this.getLocations();
    }
}
</script>
