<template>
  <div class="logout">
  Logout...
  </div>
</template>

<script>

export default {
    created(){
        window.console.log('Hace logout');
        this.$ls.remove('token');
        this.$ls.remove('customer_id');
        this.$store.commit('logout');
        this.$router.push('/login');
    }
}
</script>
