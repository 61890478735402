<template>
  <div class="locations text-center py-3">
        <v-toolbar
          color="#E21A22"
          dark
        > 
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
        </v-toolbar>
          <v-row>
            <v-col>
            <v-data-table
                :headers="headers"
                :items="locations"
                :items-per-page="10"
                :search="search"
                class="elevation-1"
              ></v-data-table>
            </v-col>
          </v-row>
  </div>
</template>
<script>
export default {
    name: 'Locations',
    data: () => ({
        locations: [],
        headers: [
            {text: 'Location', value:'serviceAddress'},
            {text: 'City', value:'city'},
            {text: 'Zip Code', value:'zipCode'},
            {text: 'Phone Number', value:'phoneNumber'},
            {text: 'E-mail', value:'email'},
        ],
        search: '',
    }),
    computed: {
    },
    watch: {
    },
    methods: {
        getLocations: function(){
          var este = this;
          este.$http.post(este.$api + 'locations', {
            customer_id: este.$store.getters.customer_id,
            token: este.$store.getters.token,
          }).then(function(response){
            if(response.status == 200 && response.data.status == 'ok'){
                  console.log('IMPRIME DATOS');
                  window.console.log(response.status);
                  este.locations = response.data.data;
            } else {
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": response.data.msg
              }
              este.locations = [];
              este.$store.commit("msgMuestra", mensaje);
              if('error' in response.data && response.data.error == 'logout'){
                  este.$router.push('/logout');
              }
            }
          }).catch(function(err){
              este.locations = [];
              var mensaje = {
                  "color": "red",
                  "timeout": 5000,
                  "message": "There was an error. Please try again."
              }
              este.$store.commit("msgMuestra", mensaje);
              window.console.log(err);
          });
        }
    },
    mounted: function(){
        this.$store.commit('title', 'Pick Up Locations');
        this.getLocations();
    }
}
</script>
