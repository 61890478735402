<template>
  <div class="calendar text-center py-3">
    <v-toolbar color="#E21A22" dark>
      <v-btn icon @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ currentMonth }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-sheet height="800">
          <v-calendar
            ref="calendar"
            v-model="today"
            :events="events"
            :event-color="getEventColor"
            type="month"
            :weekdays="weekdays"
            @change="getEvents"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Calendar",
  data: () => ({
    events: [],
    today: "",
    weekdays: [1, 2, 3, 4, 5],
    currentMonth: ""
  }),
  computed: {},
  watch: {
    today: function() {
      this.currentMonth = this.$moment(this.today).format("MMMM, YYYY");
    }
  },
  methods: {
    getEventColor: function(event) {
      return event.color;
    },
    getEvents: function() {
      var este = this;
      este.$http
        .post(este.$api + "routes", {
          customer_id: este.$store.getters.customer_id,
          token: este.$store.getters.token,
          date: este.today
        })
        .then(function(response) {
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            window.console.log(response.status);
            este.events = response.data.data;
            console.log(este.events);
          } else {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg
            };
            este.manifests = [];
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function(err) {
          este.manifests = [];
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
      console.log("New Events");
      console.log(this.today);
      this.$refs.calendar.updateTimes();
    }
  },
  mounted: function() {
    let este = this;
    this.today = this.$moment().format("YYYY-MM-DD");
    //this.currentMonth = this.$moment().format("MMMM, YYYY");
    this.$store.commit("title", "Pick Up Calendar");
    setTimeout(() => {
      este.getEvents();
    }, 1000);
  }
};
</script>
