<template>
  <div class="onlineTraining text-center py-3">
    <v-toolbar color="#E21A22" dark>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-toolbar>
    <v-row>
      <v-col cols="12" md="3">
        <v-card color="blue darken-1" dark>
          <v-card-title class="headline">Licenses</v-card-title>

          <v-card-text>
            <div class="text-left" v-text="'Total: ' + totalLicenses"></div>
            <div class="text-left" v-text="'Available: ' + availableLicenses"></div>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="toggleForm" v-if="availableLicenses > 0">Assign License</v-btn>
          </v-card-actions>
        </v-card>
        <div v-if="showForm">
          <v-text-field v-model="name" label="Name"></v-text-field>
          <v-text-field v-model="email" label="E-mail"></v-text-field>
          <v-btn color="primary" :loading="loading" :disabled="loading" @click="submit">Assign</v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="9">
        <v-data-table :headers="headers" :items="trainingLicenses" :items-per-page="10" :search="search"
          class="elevation-1">
          <template v-slot:item.link="{ item }">
            <v-btn class="ma-2" dark color="success" @click="copylink(item)">
              Link <v-icon>mdi-link-variant</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.pdf="{ item }">
            <v-btn class="ma-2" dark color="red" @click="download(item)" v-if="item.code">
              Download <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "DOT-RMW Online Training",
  data: () => ({
    headers: [
      { text: "Name", value: "name" },
      { text: "E-mail", value: "email" },
      { text: "Date", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Attempts", value: "try" },
      { text: "Link", value: "link" },
      { text: "Certificate", value: "pdf" }
    ],
    search: "",
    trainingLicenses: [],
    totalLicenses: 0,
    availableLicenses: 0,
    showForm: false,
    name: "",
    email: "",
    loading: false
  }),
  computed: {},
  watch: {},
  methods: {
    toggleForm: function () {
      this.showForm = !this.showForm;
      console.log(this.showForm);
    },
    download: function (pdf) {
      if (process.env.NODE_ENV == "development") {
        window.open(
          "http://prima.test/dotcertificatePDF/" + pdf.id + "/" + pdf.code,
          "_blank"
        );
      } else {
        window.open(
          "https://primawm.com/dotcertificatePDF/" + pdf.id + "/" + pdf.code,
          "_blank"
        );
      }
    },
    copylink: function (link) {
      let este = this;
      console.log(link.link);
      // copy link.link to clipboard
      navigator.clipboard
        .writeText(link.link)
        .then(() => {
          console.log("Link has been copied to clipboard");
          var mensaje = {
            color: "green",
            timeout: 5000,
            message: "Link has been copied to clipboard"
          };
          este.$store.commit("msgMuestra", mensaje);
        })
        .catch(err => {
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "Unable to copy link to clipboard"
          };
          este.$store.commit("msgMuestra", mensaje);
          console.log("Unable to copy link to clipboard", err);
        });
    },
    reset: function (item) {
      var este = this;
      console.log(item);
      este.$http
        .post(este.$api + "dotreset", {
          customer_id: este.$store.getters.customer_id,
          token: este.$store.getters.token,
          id: item.id
        })
        .then(function (response) {
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            este.getTrainingLicenses();
            window.console.log(response.status);
          } else {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg
            };
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function (err) {
          este.manifests = [];
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    getTrainingLicenses: function () {
      var este = this;
      este.$http
        .post(este.$api + "dottrainingLicenses", {
          customer_id: este.$store.getters.customer_id,
          token: este.$store.getters.token
        })
        .then(function (response) {
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            window.console.log(response.status);
            este.trainingLicenses = response.data.data;
            este.totalLicenses = response.data.totalLicenses;
            este.availableLicenses = response.data.availableLicenses;
          } else {
            var mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg
            };
            este.trainingLicenses = [];
            este.totalLicenses = 0;
            este.availableLicenses = 0;
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function (err) {
          este.manifests = [];
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    },
    clean: function () {
      this.name = "";
      this.email = "";
      this.showForm = false;
    },
    submit: function () {
      console.log("Submit");
      var este = this;
      este.loading = true;
      if (!este.name || !este.email) {
        var mensaje = {
          color: "red",
          timeout: 5000,
          message: "Name and email are required."
        };
        este.$store.commit("msgMuestra", mensaje);
        este.loading = false;
        return true;
      }
      este.$http
        .post(este.$api + "dotassignLicense", {
          customer_id: este.$store.getters.customer_id,
          token: este.$store.getters.token,
          name: este.name,
          email: este.email
        })
        .then(function (response) {
          var mensaje = {};
          este.loading = false;
          if (response.status == 200 && response.data.status == "ok") {
            console.log("IMPRIME DATOS");
            window.console.log(response.status);
            este.clean();
            este.trainingLicenses = response.data.data;
            este.totalLicenses = response.data.totalLicenses;
            este.availableLicenses = response.data.availableLicenses;
            mensaje = {
              color: "green",
              timeout: 5000,
              message: response.data.msg
            };
            este.$store.commit("msgMuestra", mensaje);
          } else {
            mensaje = {
              color: "red",
              timeout: 5000,
              message: response.data.msg
            };
            este.$store.commit("msgMuestra", mensaje);
            if ("error" in response.data && response.data.error == "logout") {
              este.$router.push("/logout");
            }
          }
        })
        .catch(function (err) {
          este.loading = false;
          var mensaje = {
            color: "red",
            timeout: 5000,
            message: "There was an error. Please try again."
          };
          este.$store.commit("msgMuestra", mensaje);
          window.console.log(err);
        });
    }
  },
  mounted: function () {
    this.$store.commit("title", "DOT-RMW Online Training");
    this.getTrainingLicenses();
  }
};
</script>
